import React from 'react';
import Bio from './Bio';
// import Galery from './Galery';
import Header from './Header';
import Contact from './Contact';
import '../styles/index.css';
import BussinesOffer from './BussinesOffer';
import ClientsSlider from './ClientsSlider';
import WhatsappIcon from './WhatsappIcon';
import Footer from './Footer';
// import Team from './Team';

const Aplication = () => {

    return (
        <div>
            <Header />
            <Bio />
            {/* <Galery /> */}
            <BussinesOffer />
            <ClientsSlider />
            {/* <Team /> */}
            <Contact />
            <Footer />
            <WhatsappIcon />
        </div>
    );
};

export default Aplication;
