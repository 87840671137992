import React from 'react';
import img1 from '../imgs/img1.svg';
import img2 from '../imgs/img2.svg';
import img3 from '../imgs/img3.svg';

function Bio() {
    function RandomImg() {
        return <img src={[img1, img2, img3][Date.now() % 3]} alt='build icon' width="60%" className='marginAuto'/>
    }

    return (
        <div className='flexWrap fullPadding'>
            <div className='box alignLeft padding width90'>
                <h2 className='center'>
                    QUIÉNES SOMOS
                </h2>
                <p className='alignLeft padding width90 marginAuto'>
                    Somos una empresa que presta servicios innovadores e integrales de acompañamiento y apoyo a empresas, en diferentes ámbitos económicos, garantizando una correcta gestión de los recursos inmobiliarios y los facilities en general.
                </p>
            </div>
            <div className='center box padding'>
                <RandomImg />
            </div>
        </div>
    );
}

export default Bio;