import React from 'react';
import { BsFillPlusSquareFill } from "react-icons/bs";

function BussinesOffer() {
    const services = [
        {
            title: 'MANTENIMIENTO LOCATIVO',
            text: 'Somos especialistas en mantenimientos preventivos o correctivos. Nuestros expertos están al servicio de tus necesidades.',
            list: ['Preventivo', 'Predictivo', 'Correctivo', 'Overhaul', 'Mejorativo o rediseño']
        },
        {
            title: 'OBRA CIVIL',
            text: 'Mejoramientos de obra civil al mejor precio con la mejor calidad.',
            list: ["Obras nuevas",
                "Remodelaciones",
                "Redes hidrosanitarias",
                "Redes eléctricas y datos",
                "Aires acondicionados"]
        },
        {
            title: 'COMPLEMENTARIOS',
            text: 'Nuestros servicios complementarios son:',
            list: ['Trámites y licencias', 'Interventoría civil', 'Diseños de ingeniería', 'Redes contra incendios']

        },
        {
            title: 'GERENCIA DE PROYECTOS',
            text: 'Gerenciamos tus proyectos con calidad profesional.',
            list: ["Elaboración de presupuestos", "Coordinación de proyectos", "Asesoría y gestión inmobiliaria"]
        },
        {
            title: 'GESTIÓN DE ACTIVOS',
            text: 'Implementamos y optimizamos procesos  para tu empresa o negocio.',
            list: ['Implementación de procesos', 'Mejora de procesos existentes']
        },
        {
            title: 'DISEÑOS ARQUITECTÓNICOS',
            text: 'Diseñamos tus proyectos arquitectónicos a nivel profesional, confiable y basado en los más altos estándares de calidad.'
        },
    ]

    // function toggleShow(idx) {
    //     var element = document.getElementById(`description_${idx}`);
    //     if (element.style.display == "none" || !element.style.display) element.style.display = 'inline'
    //     else element.style.display = "none";
    // }
    return (
        < div className='fullPadding fontSmall bgColor2'>
            <div className='paddingH'>
                <h2 className='noMargin center padding'>NUESTROS SERVICIOS</h2>
                <div className='paddingTop width90 marginAuto'>
                    {services.map((card, idx) => (
                        <div className='borderBottom' key={idx}>
                            <div><h3> <BsFillPlusSquareFill /> <b className='marginLeft color1'> {card.title}</b></h3></div>
                            <div id={`description_${idx}`}>
                                <div >{card.text}</div><br />
                                {card.list ? (
                                    card.list.map((e, idx) => (
                                        <li className='marginLeft fontSmall' key={idx}>{e}</li>
                                    ))
                                )
                                    : <></>
                                }
                                <br />
                            </div>
                        </div>
                    )
                    )}
                </div>
            </div>
        </ div >
    );
}

export default BussinesOffer;