import React from 'react';
import logo from "../imgs/logo.svg";
import cf from "../imgs/cf.png";
import { BsFacebook, BsInstagram, BsTwitter, BsYoutube } from "react-icons/bs";

function Footer() {
    return (
        <div className=''>
            <div className='fullPadding bgColor1'>
                <div className='footerBox fontWhite'>
                    <div className='rightBox padding'>
                        <div className="footerRight"><h3 className=" color4">HORARIO DE ATENCIÓN</h3>
                            <span className="color2">Lunes a viernes: 8:00am - 6:00pm </span><br />
                            <span className="color2">Sábados: 8:00 am - 1:00 pm </span>
                            <div className=''>
                                <br />
                                <h3 className="color4">CONTÁCTANOS</h3><a href="tel:+573007751503" className="footerVerticalCenter"><span className="footerMarginH"></span>(+57) 300 7751503</a><br /><a href="mailto:gsierra@guiararquitectura.com" className="footerVerticalCenter"><span className="footerMarginH"></span>gsierra@guiararquitectura.com</a><a href="https://goo.gl/maps/f2Xjw2E11vvSiMXdA" target="_blank" rel="noreferrer"><br />Calle 7 # 80-75 INT 310 <br /> Medellín, Colombia</a><br />
                            </div>
                        </div>
                    </div>
                    <div className='leftBox'>
                        <h3 className='' >SÍGUENOS</h3>
                        <div>
                            <a href='#' className='socialIcons'><BsFacebook /></a>
                            <a href='#' className='socialIcons'><BsInstagram /></a>
                            <a href='#' className='socialIcons'><BsTwitter /></a>
                            <a href='#' className='socialIcons'><BsYoutube /></a>
                            <br />
                            <img src={logo} alt="" className='padding' width={"340px"} />
                        </div>
                    </div>
                    <br />
                </div>
            </div>
            <div className='padding bgColor2'>
                <a href='https://www.compufacilito.com/' target="_blank" rel="noreferrer" style={{ textAlign: 'right', marginLeft: "0rem", cursor: "pointer", color: "black", display: 'flex', alignItems: 'center' }}>
                    <img src={cf} alt="logo" width={50} />
                    <span className='marginLeft'>creado por compuFacilito</span>
                </a>
            </div>
        </div>
    );
}

export default Footer;