import React from 'react';
import { BsFillArrowLeftCircleFill, BsFillArrowRightCircleFill } from "react-icons/bs";

function ClientsSlider() {


    const slideBrands = () => {
        if (document.getElementsByClassName('spriteImg')[0]) {
            var sprite = document.getElementsByClassName('spriteImg')[0];
            !sprite.style.backgroundPosition ? sprite.style.backgroundPosition = "0px" : console.log();
            var position = parseInt(sprite.style.backgroundPosition)
            position -= 1;
            sprite.style.backgroundPosition = `${position}px`;
        }
        setTimeout(slideBrands, 25);
    }
    slideBrands();

    const slideSlider = (direction = "left") => {
        var element = document.getElementsByClassName('spriteImg')[0];
        var position = parseInt(element.style.backgroundPosition);
        if (direction == 'right') element.style.backgroundPosition = position + 200 + "px";
        else element.style.backgroundPosition = position - 200 + "px"
    }

    return (
        <div className='fullPadding relative'>
            <h2 className='center'>NUESTRA EXPERIENCIA</h2>
            <div className='spriteImg'></div>
            <span className='sliderIconLeft' onClick={() => slideSlider('left')}><BsFillArrowLeftCircleFill /></span>
            <span className='sliderIconRight' onClick={() => slideSlider('right')}><BsFillArrowRightCircleFill /></span>
        </div>
    )
}

export default ClientsSlider