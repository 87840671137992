import React from 'react';
import logo from '../imgs/logo.svg';

function Header() {
    return (
        <div className='bgColor1 headerBox flexWrap paddingBottom'>
            <img src={logo} alt='guiar logo' className='bannerTitle' />
        </div>
    );
}

export default Header;