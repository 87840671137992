import React, { useState } from 'react';
import mapImg from '../imgs/map.png';

function Contact() {
    const [msg, setmsg] = useState(null);
    const [email, setemail] = useState(null);
    const [name, setname] = useState(null);

    function saveData(cb = console.log) {
        var url = 'https://script.google.com/macros/s/AKfycbyKAeZJEaGqj5y75bkIcvNxhQu0v5slDS8hkT7iDl9emRsGNJt7261S9M-BWRqU3tSO/exec';
        var endpoint = '?name=' + name + '&email=' + email + '&msg=' + msg;
        fetch(url + endpoint)
            .then(e => e.text())
            .then(e => cb(e))
    };

    function clearInputs() {
        setname(undefined);
        setemail(undefined);
        setmsg(undefined);
    }

    return (
        <div className=''>
            <div className='flexWrap  bgColor2 fullPadding'>
                <div className='box'>
                    <h2 className='center'>UBÍCANOS</h2>
                    <div id='imgBox1' className='card noPadding relative paddingBottom'>

                        <a href='https://goo.gl/maps/f2Xjw2E11vvSiMXdA' target='_blank' rel="noreferrer">
                            <img className='map' src={mapImg} alt='map location' />
                            <span className='absolute' id='pinIcon'>
                            </span>
                            <div className='padding color1 center'>Calle 7 # 80-75 INT 310 <br /> Medellín, Colombia 📌</div>
                        </a>
                    </div>
                </div>
                <div className='bgColor2 box'>
                <br /> <br />
                    <h2 className='center'>CONTÁCTANOS</h2>
                    <div className='card padding'>
                        <div className='relative center marginAuto marginTop'>
                            <label className='inputLabel' htmlFor="name">Nombres</label>
                            <input className='input width90' value={name || ""} id='name' type="text" placeholder='Jhon Doe' onChange={(e) => setname(e.target.value)} />
                        </div>
                        <div className='relative center marginAuto'>
                            <label className='inputLabel' htmlFor="name">Correo</label>
                            <input className='input width90' value={email || ""} id='email' type="email" placeholder='jhon@doe.com' onChange={(e) => setemail(e.target.value)} />
                        </div>
                        <div className='relative center marginAuto'>
                            <label className='inputLabel' htmlFor="name">Detalles</label>
                            <textarea rows="5" value={msg || ""} className='input width90' id='details' type="email" placeholder={'Hola, me interesan tus excelentes servicios...'} onChange={(e) => setmsg(e.target.value)} />
                        </div>
                        <a href={encodeURI("https://api.whatsapp.com/send?phone=573007751503&text=" + msg)} onClick={() => saveData(console.log)} target='_blank' rel="noreferrer" className='center'>
                            <button className='btn2 width90 marginAuto marginBottom' onClick={() => clearInputs()}>Enviar</button>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Contact;