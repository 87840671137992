import Aplication from "./components/Aplication";

function App() {
  return (
    <div className="App">
      <Aplication />
    </div>
  );
}

export default App;
