import React from 'react';
import whatsappIcon from '../imgs/whatsapp.png';

function WhatsappIcon() {
    return (
        <div className='contactIcon'>
            <a href='https://api.whatsapp.com/send?phone=573007751503' rel="noreferrer" target='_blank' >
                <img src={whatsappIcon} alt="whatsapp icon" width="70" onClick={() => document.getElementById('name').focus()} />
            </a>
        </div>
    );
}

export default WhatsappIcon;